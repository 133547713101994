<template>
  <div class="container-fluid mt-3">
    <form @submit="handleSubmitForm">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الصفحات' , path: '/pages'}" current_page="تعديل الصفحة"/>
        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="button" @click="handleSubmitForm" :disabled="isSubmitting">حفظ</button>
        </div>
      </div>
      <page-form v-if="!loading" :editor-content="editorContent"/>
      <Loader v-else/>
    </form>
  </div>
</template>

<script setup>
import Breadcrumb from "@/components/Breadcrumb";
import PageForm from './component/PageForm'
import {useForm} from "vee-validate";
import {useToast} from 'vue-toastification';
import {useRouter, useRoute} from 'vue-router';
import {onMounted, ref, watchEffect} from "vue";
import {getPage, editPage} from "@/services/pages";
import Loader from "../../components/Loader";

const toast         = useToast();
const router        = useRouter();
const route         = useRoute();
const editorContent = ref('')
const loading       = ref(true)
const schema        = {
  name       : 'required',
  description: 'required',
  content    : 'required'
};

const {setValues, handleSubmit, isSubmitting} = useForm({validationSchema: schema})

onMounted(async () => {
  const result         = await getPage(route.params.id);
  const {status, data} = result;
  if (status === 200)
  {
    setValues(
        {
          'name'       : data.name,
          'description': data.description,
          'content'    : data.content
        }
    )

    editorContent.value = data.content
  }
  loading.value = false
})

const handleSubmitForm = handleSubmit(async (values, {resetForm}) => {
  try
  {
    const result = await editPage(route.params.id, values)
    toast.success('تم الحفظ بنجاح')
    await router.push('/pages')
  } catch (e)
  {
    toast.error('حدث خطأ, يرجى المحاولة مرة أخرى')
  }
})

</script>
