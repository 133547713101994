<template>
  <el-card class="custom-card border">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="بيانات الصفحة" name="pageDetails">

        <div class="container-fluid">
          <div class="row mb-3">
            <div class="col-lg-9">
              <div class="form-group">
                <label for="name">اسم الصفحة</label>
                <input type="text" v-model="name" name="name" id="name" class="form-control"
                       :class="{'border-danger' : nameError}"
                >
                <span class="text-danger">{{ nameError }}</span>
              </div>

              <div class="form-group mt-3">
                <label for="description">وصف الصفحة</label>
                <textarea name="description" v-model="description" id="description" class="form-control" rows="5"
                          :class="{'border-danger' : descriptionError}"
                />
                <span class="text-danger">{{ descriptionError }}</span>
              </div>

              <div class="form-group mt-3 ">
                <label for="description">محتوى الصفحة</label>

                <div class="editor-border mb-2" :class="{'border-danger' : contentError}">
                  <CustomCKEditor :content="editorContent" @updateContent="val=>content = val"/>
                </div>

                <span class="text-danger">{{ contentError ? 'الرجاء إدخال محتوى الصفحة' : '' }}</span>

              </div>


            </div>
          </div>
        </div>


      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script setup>
import {ref} from 'vue'
import CustomCKEditor from "@/components/editor";
import {useField} from "vee-validate";

const props = defineProps(
    {
      editorContent: {
        type: String,
        default: '<div></div>'
      }
    }
)

const activeTab = ref('pageDetails');

const {value: name, errorMessage: nameError}               = useField('name');
const {value: description, errorMessage: descriptionError} = useField('description');
const {value: content, errorMessage: contentError}         = useField('content');


</script>

<style scoped>
.form-control {
  max-width: 500px;
  font-size: .875rem;
}

.editor-border {
  border: 1px solid transparent;
}
</style>
