import request from "@/utils/request";

export function getAllPages() {
  return request(
    {
      url: 'pages',
      method: 'get'
    }
  )
}


export function createPage(data){
  return request(
    {
      url: 'pages',
      method: 'post',
      data
    }
  )
}

export function getPage(id) {
  return request(
    {
      url: `pages/${id}`,
      method: 'get'
    }
  )
}

export function editPage(id,data) {
  return request (
    {
      url: `pages/${id}`,
      method: 'put',
      data
    }
  )
}
